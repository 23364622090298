(function (angular, _) {
    var MyHippoCommonDirectives = angular.module('MyHippoCommons.Directives');

    MyHippoCommonDirectives.directive('mhModalWrapper', mhModalWrapperDirective);
    function mhModalWrapperDirective () {
        const {_} = window;
        return {
            scope: {
                acceptAction: '=',
                acceptCopy: '@',
                rejectAction: '=',
                rejectCopy: '@',
                icon: '@',
                iconColor: '@',
                class: '@'
            },
            restrict: 'E',
            transclude: true,
            template: `
                <div class="modal-wrapper {{class}}">
                    <div class="wrapper-content">
                        <div ng-if="icon" class="icon-wrapper">
                            <i class="fa {{icon}} {{iconColor}}" aria-hidden="true"></i>
                        </div>
                        <ng-transclude></ng-transclude>
                    <div>
                    <div class="wrapper-actions" ng-if="acceptAction || rejectAction">
                        <button class="accept" ng-if="acceptAction" ng-click="acceptAction()">{{acceptCopy}}</button>
                        <button class="reject" ng-if="rejectAction" ng-click="rejectAction()">{{rejectCopy}}</button>
                    <div>
                </div>
            `
        };
    }
})(window.angular, window._);
